export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDJYl1WJrPxcsP0dEz7NQ2NwaT0ejibIQQ",
    authDomain: "niwi-ai-mobile-app.firebaseapp.com",
    databaseURL: "https://niwi-ai-mobile-app-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "niwi-ai-mobile-app",
    storageBucket: "prim-mobile-app",
    messagingSenderId: "80740292131",
    appId: "1:80740292131:web:8ba2d49c4ce38da00f8396",
    measurementId: "G-QKZ9VS27BQ",
    vapidKey: "BFloCHzbIK7BHpWNSTtakr3U-gS1eZ7JkOcYz1Sc0D1EapFRi2fUIDCn-SXs2BlP3P_OqLrdPk8RGsu1Jc0SPnI"
  },
  api:{
    image_url:'https://crm.niwi.ai/assets/uploads/niwiai_images/',
    crm_image_url: 'https://crm.niwi.ai/assets/uploads/niwiai_images/',
    package_url: 'https://crm.niwi.ai/assets/uploads/packages/',
    url: 'https://grow.primhub.ai',
    coach_url: 'https://coach.chaitanyapuri.com/api',
    ccavenue_url: 'https://app.niwi.ai/payment',
    merchant_id: '113981',
    razorpay_key: 'rzp_live_xWQ2AykrJwxkG7',
    direct_pay: 'https://app.niwi.ai/payment/domestic.php',
    international_pay: 'https://app.niwi.ai/payment/international.php',
    ccavenue_request: 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction',
  },
  shop:{
    enableKart: true
  },
  app:{
    app_url: 'https://diet.primhub.ai',
    appVersion:'33.0'
  },
  firestoreCollection: 'chats'
};
